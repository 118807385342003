import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import KnockoutPage, { KnockoutPageData } from 'templates/KnockoutPage';

export const query = graphql`
  query {
    csKnockoutPage(page_id: { eq: "temporary-licence-restrictions" }) {
      ...KnockoutPage
    }
  }
`;

const LicenceRestriction: React.FC = () => {
  const data = useStaticQuery<KnockoutPageData>(query);
  return <KnockoutPage data={data} />;
};

export default LicenceRestriction;
